<!--
 * @Description: 从课程商城中选择
 * @Author: xiawenlong
 * @Date: 2021-04-28 15:44:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-19 10:03:01
-->
<template>
  <div class="add-course-by-shop">
    <table-list
      title="课程列表"
      :loading="loading"
      :data="courseList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{
        selection: true,
        reserveSelection: true,
        rowKey: 'productId',
        selectable: selectEvent,
      }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div slot="filter" class="subTitle">
        <el-checkbox v-model="isCollect" @change="isCollectChange">查看收藏夹的课程</el-checkbox>
      </div>
    </table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '确认添加课程',
    method: _this.handleAdd,
    type: 'primary',
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '课程名称',
    prop: 'courseName',
  },
  {
    label: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播' },
      { value: 1, label: '直播' },
    ],
  },
  {
    label: '发布时间',
    type: 'picker',
    prop: 'createTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'mobile',
    label: '课程封面',
    minWidth: 160,
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'height: 50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'name',
    label: '课程名称',
    minWidth: 200,
  },
  {
    prop: 'courseType',
    label: '课程类型',
    minWidth: 80,
    formatter: row => {
      return { 0: '录播', 1: '直播', 9: '未知' }[row.courseType]
    },
  },
  {
    prop: 'teacherName',
    label: '任课老师',
    minWidth: 120,
  },
  {
    prop: 'description',
    label: '课程特点',
    minWidth: 200,
  },
  {
    prop: 'price',
    label: '价格',
    minWidth: 200,
    render: (h, { row }) => {
      return [
        row.isQuantity ? h('p', '批量按量：¥' + row.quantityPrice + '元') : null,
        row.isBuyout ? h('p', '按年买断：¥' + row.buyoutPrice + '元') : null,
      ]
    },
  },
  {
    prop: 'chapterCount',
    sortable: true,
    minWidth: 100,
    label: '章节数',
  },
  {
    prop: 'durationTotal',
    sortable: true,
    minWidth: 120,
    label: '课程时长（分钟）',
    formatter: row => {
      return row.durationTotal
    },
    align: 'right',
  },
]
import TableList from '@/components/TableList'
import { classCourseAddShop, courseShopList } from '@/api/class'
import to from 'await-to'
export default {
  name: 'AddCourseByShop',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      isCollect: false,
      queryParams: {},
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    async getCourse() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(
        courseShopList({
          classId,
          byMyCollection: this.isCollect ? 0 : -1,
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.pager.total = res.data.total
    },
    isCollectChange() {
      this.getCourse()
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.startTime = queryParams.createTime[0]
        queryParams.endTime = queryParams.createTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.getCourse()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
    },
    async handleAdd() {
      const { classId } = this.$route.params
      if (!this.selectCourse.length) return this.$message.warning('请先选择课程')
      const productId = this.selectCourse.map(item => item.productId)
      const [, err] = await to(classCourseAddShop({ classId, productId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push(`/class/addCourse/${classId}`)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourse()
    },
    selectEvent(row) {
      return !row.flag
    },
  },
}
</script>
<style lang="scss" scoped>
.add-course-by-shop {
  ::v-deep.table-list {
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      display: none;
    }
  }
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-left: 40px;
  }
}
</style>
